import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EventLogos from './EventLogos';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';

const InitialLanding = ({ showInitialLanding }) => {
  return (
    <InitialLandingWrapper showInitialLanding={showInitialLanding}>
      <EventLogos showInitialLanding={showInitialLanding} />
      <SignUpWrapper>
        <StyledSignUpText>
          BE PART OF THIS COMPETITION
          AND SHOW US WHAT YOU CAN DO!
        </StyledSignUpText>
        <Link href="https://www.out.com/keywestbartenders" target="_blank" rel="noopener noreferrer">
          <StyledSignUpButton>SIGN UP HERE</StyledSignUpButton>
        </Link>
      </SignUpWrapper>
    </InitialLandingWrapper>
  );
};

const InitialLandingWrapper = styled.div`
  width: 34.53125vw;
  height: 27.6041666667vw;
  margin-right: 2.0833333333vw;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
  overflow: hidden;

  @media (max-width: ${breakpoints.md.max}px) {
    z-index: 0;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    width: 70.1333333333vw;
    height: 113.8666666667vw;
    top: 21.0666666667vw;
    left: 15.2vw;
    justify-content: flex-start;
  }
`;

const SignUpWrapper = styled.div`
  max-width: 34.53125vw;
  margin: 2.0833333333vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.xs.max}px) {
  display: flex;
  max-width: 70.1333333333vw;
  margin-top: 0;
  top: 28.4vw;
  z-index: -1;
  }
`;

const StyledSignUpText = styled.p`
  max-width: 29.5833333333vw;
  margin-bottom: 0.3125vw;
  text-align: center;
  color: #000000;
  font-family: Verdana;
  font-size: 1.3020833333vw;
  font-weight: 700;
  letter-spacing: 0.06510416667vw;
  line-height: 1.5625vw;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.xs.max}px) {
    max-width: 63.2vw;
    margin-bottom: 2.6666666667vw;
    font-size: 3.7333333333vw;
    letter-spacing: 0.1866666667vw;
    line-height: 4.8vw;
  }
`;


const StyledSignUpButton = styled.button`
  width: 16.9270833333vw;
  height: 3.8020833333vw;
  border-radius: 1.9270833333vw;
  border: none;
  background-image: ${colors.signUpButton};
  color: white;
  font-family: Tahoma;
  font-size: 1.3020833333vw;
  font-weight: 700;
  letter-spacing: 0.1302083333vw;
  text-transform: uppercase;
  outline-color: ${colors.blue};
  cursor: pointer;

  @media (max-width: ${breakpoints.xs.max}px) {
    width: 45.6vw;
    height: 10.1333333333vw;
    border-radius: 9.8666666667vw;
    font-size: 3.2vw;
    letter-spacing: 0.32vw;
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  color: ${colors.white};
  border: none;
`;

InitialLanding.propTypes = {
  showInitialLanding: PropTypes.bool,
};

InitialLanding.defaultProps = {
  showInitialLanding: false,
};

export default InitialLanding;
