import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { map } from 'lodash';
import keyWest from '../assets/images/key-west-logo.png';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';

class CardSection extends Component {
  mapEventWinners = () => {
    const { events } = this.props;
    return map(events, event => event.winner ? ({
      ...event.winner,
      city: event.city,
    }) : ({
      city: event.city,
    }));
  }

  renderCards = () => {
    return map(this.mapEventWinners(), (winner, index) => (
      <Card key={index}>
        {winner.image_url ? (
          <CardImage
            src={winner.image_url}
          />
        ) : (
          <PlaceholderWrapper>
            <Placeholder
              src={keyWest}
            />
          </PlaceholderWrapper>
        )}
        <CardInfo>
          <NameCityContainer>
            <WinnerName>
              {winner.name ? winner.name : 'Winner'}
            </WinnerName>
            <City>
              {winner.city}
            </City>
          </NameCityContainer>
          <Line />
          <Charity>
            {winner.local_charity_name || winner.stoli_charity_name || 'BARTENDERS’ HOMETOWN CHARITY'}
          </Charity>
        </CardInfo>
      </Card>
    ));
  }

  render = () => {
    const { style, isPostFinale } = this.props;
    return (
      <CardsWrapper
        style={style}
        isPostFinale={isPostFinale}
      >
        {this.renderCards()}
      </CardsWrapper>
    );
  }
}

CardSection.propTypes = {
  style: PropTypes.object,
  events: PropTypes.array,
  isPostFinale: PropTypes.bool,
};

CardSection.defaultProps = {
  style: {},
  events: [],
  isPostFinale: false,
};

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${props => (!props.isPostFinale ? '60px' : '0px')};
`;

const Card = styled.div`
  width: 16.015625vw;
  margin-bottom: 2.1875vw;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.15);
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.md.max}px) {
    width: 27.34375vw;
    margin-bottom: 2.6041666667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    width: 77.3333333333vw;
    margin-bottom: 4.5333333333vw;
  }
  & + & {
    margin-left: 1.015625vw;
    @media (max-width: ${breakpoints.md.max}px) {
      margin-left: 1.6927083333vw;
    }
    @media (max-width: ${breakpoints.sm.max}px) {
      margin-left: 0;
    }
  }
  &:nth-child(5n) {
    margin-left: 0;
    @media (max-width: ${breakpoints.md.max}px) {
      margin-left: 1.6927083333vw;
    }
  }
  &:nth-child(4n) {
    @media (max-width: ${breakpoints.md.max}px) {
      margin-left: 0;
    }
  }
`;

const CardImage = styled.div`
  height: 12.109375vw;
  width: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${breakpoints.md.max}px) {
    height: 20.5729166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    height: 58.6666666667vw;
  }
`;

const Placeholder = styled.img`
  height: 12.109375vw;
  opacity: 0.2;

  @media (max-width: ${breakpoints.md.max}px) {
    height: 20.5729166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    height: 58.6666666667vw;
  }
`;

const CardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.015625vw 0 0.546875vw;
  @media (max-width: ${breakpoints.md.max}px) {
    padding: 0.9114583333vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    padding: 1.8666666667vw;
  }
`;

const NameCityContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WinnerName = styled.div`
  font-size: 0.9375vw;
  font-family: 'Tahoma-Bold';
  line-height: 1.39;
  letter-spacing: 1px;
  text-align: center;
  
  @media (max-width: ${breakpoints.md.max}px) {
    line-height: 1.29;
    font-size: 1.8229166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    line-height: 1.5;
    font-size: 3.2vw;
  }
`;

const City = styled.div`
  line-height: 1.39;
  font-size: 0.9375vw;
  font-family: 'Tahoma';
  @media (max-width: ${breakpoints.md.max}px) {
    line-height: 1.29;
    font-size: 1.8229166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    line-height: 1.5;
    font-size: 3.2vw;
  }
`;

const Line = styled.div`
  width: 100%;
  margin: 0.4166666667vw 0 0.6770833333vw 0;
  border: 0.5px solid ${colors.cardLine};
`;

const Charity = styled.div`
  color: ${colors.cardCharity};
  font-family: Tahoma;
  font-size: 0.7291666667vw;
  font-weight: 400;
  letter-spacing: 0.03645833333vw;
  line-height: 1.3020833333;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.4vw;
    font-weight: 400;
    letter-spacing: 0.06510416667vw;
    line-height: 3.2552083333vw; 
  }

  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 2.4vw;
    font-weight: 400;
    letter-spacing: 0.06510416667vw;
    line-height: 3.2552083333vw; 
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    font-size: 2.6666666667vw;
    font-weight: 400;
    letter-spacing: 0.1333333333vw;
    line-height: 6.6666666667vw; 
  }
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;


export default CardSection;
