import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CollapsedRow from './CollapsedRow';
import colors from '../utils/colors';
import One from '../assets/svg/one.svg';
import Spinner from '../assets/images/spinner.gif';

const headers = ['Place', 'Name'];
const columnWidth = ['30%', '40%'];

class StandingsAccordion extends Component {
  renderPlace = (index) => {
    const endings = ['st', 'nd', 'rd', 'th'];

    return index === 0 ? (
      <Place>
        <StyledOne />
        <Ending first>{endings[index]}</Ending>
      </Place>
    )
      : (
        <Place>
          <Number>{index + 1}</Number>
          <Ending>{index > 3 ? endings[3] : endings[index]}</Ending>
        </Place>
      );
  }

  renderHeaders = () => {
    return (
      <TableRow>
        {headers.map((header,
          index) => <TableHeader width={columnWidth[index]}>{header}</TableHeader>)}
      </TableRow>
    );
  }

  renderData = () => {
    const { standings, referentialScore, isLA } = this.props;
    return standings.map((standing, index) => (
      <CollapsedRow
        standing={standing}
        isTie={standing.tie}
        index={index}
        last={index === standings.length - 1}
        key={index}
        columnWidth={columnWidth}
        referentialScore={referentialScore}
        isLA={isLA && index < 3}
      />
    ));
  }

  render = () => {
    const { style, loading } = this.props;
    return (
      loading ? <StyledSpinner src={Spinner} /> : (
        <Table style={style}>
          {this.renderHeaders()}
          {this.renderData()}
        </Table>
      ));
  }
}

const Table = styled.div`
  width: 100%;
  background-color: white;
  padding: 28px 22px 0;
`;

const TableRow = styled.div`
  display: flex;
  position: relative;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Tahoma';
  font-size: 11px;
  width: ${props => props.width};
  color: ${colors.tableHeader};
`;

const Place = styled.div`
  display: flex;
`;

const StyledOne = styled(One)`
  max-width: 13px;
`;

const Number = styled.div`
  font-size: 27px;
`;

const Ending = styled.div`
  font-size: 14px;
  font-family: 'SignPainter';
  line-height: ${props => props.first ? '24' : '10'}px;
`;

const StyledSpinner = styled.img`
  height: 34px;
  margin-top: 40px;
`;

StandingsAccordion.propTypes = {
  style: PropTypes.object,
  standings: PropTypes.array,
  referentialScore: PropTypes.number,
  isLA: PropTypes.bool,
  loading: PropTypes.bool,
};

StandingsAccordion.defaultProps = {
  style: {},
  standings: [],
  referentialScore: 0,
  isLA: false,
  loading: false,
};

export default StandingsAccordion;
