import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../utils/colors';
import breakpoints from '../utils/breakpoints';
import CardSection from './CardSection';

class ChampionshipEventDetails extends Component {
  handleVoteRedirect = () => {
    window.location = 'fan_favourite';
  };

  render = () => {
    const { style, events, isPostFinale } = this.props;
    return (
      <Wrapper style={style}>
        {!isPostFinale && (
          <StyledEventGradient>
            <InnerWrapper>
              <FootnoteTitle>
                The Key West Pride Week Finale
                <br />
                <span>June 1-8, 2020</span>
              </FootnoteTitle>
              <Description>
                For photos from this competition as well as other exciting updates,
                 stay in touch at:&nbsp;
                <Link href="https://www.out.com/keywestcocktailclassic" target="_blank">
                  <strong>https://www.out.com/keywestcocktailclassic</strong>
                </Link>
              </Description>
            </InnerWrapper>
          </StyledEventGradient>
        )}
        <CardSection events={events} isPostFinale={isPostFinale} />
      </Wrapper>
    );
  };
}

ChampionshipEventDetails.propTypes = {
  style: PropTypes.object,
  events: PropTypes.array,
  isPostFinale: PropTypes.bool,
};

ChampionshipEventDetails.defaultProps = {
  style: {},
  events: [],
  isPostFinale: false,
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  font-family: "Tahoma";
`;

const StyledEventGradient = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 4.8828125vw 0;
  background-color: rgba(230, 230, 230, .45);
  margin-top: 6.25vw;
  @media (max-width: ${breakpoints.md.max}px) {
    padding: 6.9010416667vw 0;
    margin-top: 10.15625vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    padding: 10.1333333333vw 0;
    margin-top: 10.1333333333vw;
  }
`;


const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FootnoteTitle = styled.h1`
  margin-bottom: 1.875vw;
  color: ${colors.winnerRed};
  font-family: Verdana;
  font-size: 1.5625vw;
  font-weight: 700;
  letter-spacing: 0.07291666667vw;
  line-height: 2.6041666667vw;
  text-align: center;

  @media (max-width: ${breakpoints.md.max}px) {
    margin-bottom: 2.734375vw;
    font-size: 3.2552083333vw;
    font-weight: 700;
    letter-spacing: 0.1953125vw;
    line-height: 4.5572916667vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    margin-bottom: 3.7333333333vw;
    font-size: 4vw;
    font-weight: 700;
    letter-spacing: 0.2vw;
    line-height: 5.8666666667vw;
  }

  /* Text style for "The Key We" */
  span {
    color: ${colors.black};
    font-size: 1.4583333333vw;

    @media (max-width: ${breakpoints.md.max}px) {
      font-size: 2.9947916667vw;
    }

    @media (max-width: ${breakpoints.xs.max}px) {
      font-size: 4vw;
    }
  }
`;

const Description = styled.div`
  max-height: 3.3333333333vw;
  min-width: 53.229167vw;
  color: ${colors.black};
  font-family: Verdana;
  font-size: 1.171875vw;
  font-weight: 400;
  letter-spacing: 0.05703125vw;
  line-height: 1.56;
  width: 43.203125vw;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${breakpoints.md.max}px) {
    max-height: 12.5vw;
    min-width: 78.125vw;
    margin-bottom: 6.9010416667vw;
    font-size: 2.6041666667vw;
    font-weight: 400;
    letter-spacing: 0.1302083333vw;
    line-height: 3.90625vw;
  }

  @media (max-width: ${breakpoints.md.max}px) {
    max-height: 21.3333333333vw;
    min-width: 77.8666666667vw;
    margin-bottom: 8.5333333333vw;
    font-size: 3.7333333333vw;
    font-weight: 400;
    line-height: 4.8vw;
    letter-spacing: 0.1866666667vw;
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  color: ${colors.black};
  @media (max-width: ${breakpoints.sm.max}px) {
    display: block;
    text-align: center;
  }
`;

export default ChampionshipEventDetails;
