import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../utils/colors';
import ArrowDown from '../assets/svg/arrow-down.svg';
import ArrowUp from '../assets/svg/arrow-up.svg';
import breakpoints from '../utils/breakpoints';

class CityDropdown extends Component {
  state = { showOptions: false, selectedOption: {} }

  showContent = () => this.setState({ showOptions: true });

  toggleContent = () => this.setState(prevState => ({ showOptions: !prevState.showOptions }))

  setSelectedOption = (option) => {
    const { onSelection } = this.props;
    this.setState({ selectedOption: option });
    this.toggleContent();
    onSelection(option);
  }

  renderOptions = () => {
    const { cities } = this.props;
    return cities.map((city, index) => (
      <DropdownOption
        key={index}
        onClick={() => this.setSelectedOption(city)}
      >
        {city.city}
      </DropdownOption>
    ));
  }

  render = () => {
    const { showOptions, selectedOption } = this.state;
    const { style, cities, showInitialLanding } = this.props;
    return (
      <Dropdown style={style} showInitialLanding={showInitialLanding}>
        <StyledDropdownGradient onClick={this.toggleContent}>
          <DropdownButton>
            {selectedOption.city || 'Select City'}
            {showOptions ? <StyledArrowUp /> : <StyledArrowDown />}
          </DropdownButton>
        </StyledDropdownGradient>

        {showOptions && (
          <DropdownContent fit={cities.length < 5}>
            <DropdownContentInner>
              {this.renderOptions()}
            </DropdownContentInner>
          </DropdownContent>
        )}
      </Dropdown>
    );
  }
}

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;
  z-index: 1;
  margin-bottom: ${props => props.showInitialLanding ? '0vw' : '8.125vw'};
  @media (max-width: ${breakpoints.md.max}px) {
    margin-bottom: 7.2916666667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    margin-bottom: 6.6666666667vw;
  }
`;

const DropdownButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${colors.white};
  text-transform: uppercase;
  font-family: 'Tahoma';
  font-size: 0.9895833333vw;
  cursor: pointer;
  text-align: left;
  margin: 0.2083333333vw;
  padding: 0.8333333333vw 0 0.8333333333vw 1.3020833333vw;
  border-radius: 2.1354166667vw;

  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.6041666667vw;
    margin: 0.5208333333vw;
    padding: 2.0833333333vw 0 2.0833333333vw 3.6458333333vw;
    border-radius: 6.1197916667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 2.9333333333vw;
    margin: 0.5208333333vw;
    padding: 2.9333333333vw 0 2.9333333333vw 4.5333333333vw;
    border-radius: 12.5333333333vw;
  }
  ::hover, ::focus {
    background-color: #2980B9;
  }
`;
const StyledDropdownGradient = styled.div`
  width: 16.0416666667vw;
  display: flex;
  background-image: linear-gradient(to right, #ff0000 0%, #ff8900 21%, #fcf200 41%, #26a200 59%, #1e2bc9 77%, #6e009f 100%, rgba(128, 128, 128, 0) 100%);
  border-radius: 2.1354166667vw;
  @media (max-width: ${breakpoints.md.max}px) {
    width: 42.7083333333vw;
    border-radius: 6.1197916667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    width: 53.3333333333vw;
    border-radius: 12.533333333vw;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  background-color: ${colors.white};
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  height: ${props => props.fit ? 'auto' : '15.625vw'};
  padding-top: 3.4375vw;
  border-radius: 1.9791666667vw 1.9791666667vw 0.4166666667vw 0.4166666667vw;
  top: 0;
  z-index: -1;
  border: 1px solid ${colors.dropDownBorder};
  width: 16.0416666667vw;
  
  @media (max-width: ${breakpoints.md.max}px) {
    width: 42.7083333333vw;
    border-radius: 4.4270833333vw 4.4270833333vw 1.5625vw 1.5625vw;
    padding-top: 8.4635416667vw;
    height: ${props => props.fit ? 'auto' : '33.8541666667vw'};
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    width: 53.3333333333vw;
    border-radius: 8.5333333333vw 8.5333333333vw 3.2vw 3.2vw;
    padding-top: 12.8vw;
    height: ${props => props.fit ? 'auto' : '45.333333333vw'};
  }
`;
const DropdownContentInner = styled.div`
  overflow-y: auto;
  height: 100%;
`;
const DropdownOption = styled.div`
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0.7291666667vw 0 0.7291666667vw 1.875vw;
  font-family: 'Tahoma';
  text-transform: uppercase;
  font-size: 1.09375vw;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.6041666667vw;
    padding: 1.3020833333vw 0 1.3020833333vw 3.3854166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 3.2vw;
    padding: 1.8666666667 0 1.8666666667 4.2666666667vw;
  }

  &:hover {
    background-color: ${colors.dropdownItemHover};
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  max-width: 0.7291666667vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.78125vw;
  @media (max-width: ${breakpoints.md.max}px) {
    right: 1.953125vw;
    max-width: 1.8229166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    right: 4vw;
    max-width: 3.7333333333vw;
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  max-width: 0.7291666667vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.78125vw;
  @media (max-width: ${breakpoints.md.max}px) {
    right: 1.953125vw;
    max-width: 1.8229166667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    right: 4vw;
    max-width: 3.7333333333vw;
  }
`;

CityDropdown.propTypes = {
  style: PropTypes.object,
  cities: PropTypes.array,
  onSelection: PropTypes.func,
  showInitialLanding: PropTypes.bool,
};

CityDropdown.defaultProps = {
  style: {},
  cities: [],
  onSelection: () => { },
  showInitialLanding: true,
};

export default CityDropdown;
