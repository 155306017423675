import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InitialLanding from './InitialLanding';
import backgroundLines from '../assets/images/background-lines.svg';
import Logo from '../assets/svg/logo-stoli-white.svg';
import stoliBottle from '../assets/images/stoli-bottle.png';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';

const Background = ({ style, children, blur, showLogo, showInitialLanding, onClick }) => {
  const handleSignup = (event) => {
    event.preventDefault();
    window.location = 'https://www.out.com/keywestbartenders';
    onClick();
  };

  return (
    <BackgroundWrapper
      style={style}
      className={blur && 'blur'}
      showLogo={showLogo}
      showInitialLanding={showInitialLanding}
    >
      {showLogo && <StyledLogo />}
      {showLogo && showInitialLanding
      && (
      <StyledRainbow
        showLogo={showLogo}
        showInitialLanding={showInitialLanding}
        onClick={handleSignup}
      />
      )}
      {showLogo && showInitialLanding && <StyledStoliBottle src={stoliBottle} />}
      {showLogo && showInitialLanding && <InitialLanding showInitialLanding={showInitialLanding} />}
      {children}
    </BackgroundWrapper>
  );
};

export const BackgroundWrapper = styled.div`
  background-color: ${colors.red};
  background-image: ${props => props.showLogo && !props.showInitialLanding && `url(${backgroundLines})`};
  background-size: 1850px;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  @media (max-width: ${breakpoints.md.max}px) {
    background-size: 1175px;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    background-size: 134.7826086957vw;;
    background-image: ${props => props.showLogo && `url(${backgroundLines})`};
  }
`;

export const StyledLogo = styled(Logo)`
  max-width: 13.90625vw;
  position: absolute;

  @media (max-width: ${breakpoints.md.max}px) {
    max-width: 17.8607467205vw;
  }

  @media (max-width: ${breakpoints.sm.max}px) {
    max-width: 16.5580182529vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    max-width: 30.1333333333vw;
  }
`;

const StyledRainbow = styled.div`
  width: 82.6041666667vw;
  height: 48.6979166667vw;
  background-image: ${props => props.showLogo && `url(${backgroundLines})`};
  background-position: ${props => props.showInitialLanding ? 'left -0.28125vw top -5.40625vw' : 'left top'};
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
  cursor: pointer;

  @media (max-width: ${breakpoints.md.max}px) {
    background-position: ${props => props.showInitialLanding && 'left -0.6510416667vw top -1.171875vw'};
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    display: none;
  }
`;

const StyledStoliBottle = styled.img` 
  width: 56.25vw;
  height: 60.6770833333vw;
  margin-left: 7.2395833333vw;
  margin-top: -1.572917vw;
  z-index: 1;
  position: relative;

  @media (max-width: ${breakpoints.xs.max}px) {
    display: none;
  }
`;

Background.propTypes = {
  style: PropTypes.object,
  blur: PropTypes.bool,
  children: PropTypes.any,
  showLogo: PropTypes.bool,
  showInitialLanding: PropTypes.bool,
  onClick: PropTypes.func,
};

Background.defaultProps = {
  style: {},
  blur: true,
  showLogo: true,
  showInitialLanding: true,
  onClick: () => {},
};

export default Background;
