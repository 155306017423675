import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import One from '../assets/svg/n1.svg';
import colors from '../utils/colors';
import Spinner from '../assets/images/spinner.gif';
import breakpoints from '../utils/breakpoints';

const headers = ['Place', 'Name', 'Overall', 'Blind Sampling', 'Text Votes', 'Cocktail', 'Presentation'];

const columnWidth = ['13%', '24%', '13%', '13%', '13%', '13%', '13%'];

class StandingsTable extends Component {
  renderPlace = (index) => {
    const endings = ['st', 'nd', 'rd', 'th'];

    return index === 0 ? (
      <Place>
        <StyledOne />
        <Ending first>{endings[index]}</Ending>
      </Place>
    )
      : (
        <Place>
          <Number>{index + 1}</Number>
          <Ending>{index > 3 ? endings[3] : endings[index]}</Ending>
        </Place>
      );
  }

  renderFraction = (numerator) => {
    const { referentialScore } = this.props;
    return (
      <Fraction>
        {numerator}
        <Denominator>{`/${referentialScore}`}</Denominator>
      </Fraction>
    );
  }

  renderHeaders = () => {
    return (
      <TableRow>
        {headers.map((header,
          index) => <TableHeader width={columnWidth[index]} key={index}>{header}</TableHeader>)}
      </TableRow>
    );
  }

  renderData = () => {
    const { standings, isLA } = this.props;
    return standings.map((standing, index) => (
      <RowWrapper>
        <TableDataRow last={index === standings.length - 1} key={index}>
          <TableData width={columnWidth[0]}>
            {this.renderPlace(index)}
          </TableData>
          <TableData width={columnWidth[1]}>
            {standing.name}
            {isLA && index < 3 && '*'}
          </TableData>
          <TableData width={columnWidth[2]}>
            {`${standing.overall || 0}%`}
            {standing.tie && (
              <TieIndicator>*TIE</TieIndicator>
            )}
          </TableData>
          <TableData width={columnWidth[3]}>
            {standing.blindSampling || 0}
          </TableData>
          <TableData width={columnWidth[4]}>
            {standing.textVotes || 0}
          </TableData>
          <TableData width={columnWidth[5]}>
            {this.renderFraction(standing.cocktail || 0)}
          </TableData>
          <TableData width={columnWidth[6]}>
            {this.renderFraction(standing.presentation || 0)}
          </TableData>
        </TableDataRow>
      </RowWrapper>
    ));
  }

  render = () => {
    const { style, loading } = this.props;
    return (
      loading ? <StyledSpinner src={Spinner} /> : (
        <Table style={style}>
          {this.renderHeaders()}
          {this.renderData()}
        </Table>
      ));
  }
}

const Table = styled.div`
  width: 68.2291666667vw;
  background-color: white;
  margin-top: 2.7083333333vw;
  @media (max-width: ${breakpoints.md.max}px) {
    margin-top: 4.5572916667vw;
  }
`;

const TableRow = styled.div`
  display: flex;
`;

const TableDataRow = styled(TableRow)`
  align-items: center;
  font-family: 'MuseoSans-500';
  padding: 1.71875vw 0;
  @media (max-width: ${breakpoints.md.max}px) {
    padding: 2.34375vw 0;
  }
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.0416666667vw;
  width: ${props => props.width};
  font-family: 'Tahoma';
  color: ${colors.tableHeader};
  margin-bottom: 2.0833333333vw;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.0416666667vw;
    margin-bottom: 1.5625vw;
  }
  &:first-child {
    @media (max-width: ${breakpoints.md.max}px) {
      justify-content: flex-start;
      padding-left: 1.0416666667vw;
    }
  }
`;

const TableData = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.width};
  text-align: center;
  position: relative;
  font-family: 'MuseoSansRounded';
  font-size: 1.5625vw;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.6927083333vw;
  }
  &:first-child {
    @media (max-width: ${breakpoints.md.max}px) {
      justify-content: flex-start;
      padding-left: 1.0416666667vw;
    }
  }
`;

const Place = styled.div`
  display: flex;
`;

const StyledOne = styled(One)`
  width: 1.3020833333vw;
  @media (max-width: ${breakpoints.md.max}px) {
    width: 1.6927083333vw;
    height: 2.9947916667vw;
  }
`;

const Number = styled.div`
  font-size: 2.34375vw;
  font-family: 'MuseoSans-500';
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.6041666667vw;
  }
`;

const Ending = styled.div`
  font-size: 1.4583333333vw;
  font-family: 'SignPainter';
  line-height: ${props => props.first ? '0.3125' : '0.1'}vw;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.3020833333vw;
  }
`;

const Fraction = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Denominator = styled.div`
  font-size: 1.0416666667vw;
  line-height: 20px;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.0416666667vw;
    line-height: 2.9947916667vw;
  }
`;

const TieIndicator = styled.div`
  position: absolute;
  top: 0.4em;
  right: -0.25em;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  font-weight: bolder;
  line-height: 0.8em;
  color: #d6af53;
`;

const RowWrapper = styled.div`
  text-align: center;
  & + & {
    border-top: solid 1px ${colors.standingsBorder};
  }
`;
// Keeping this in case we need this later on
// const Succession = styled.div`
//   font-family: 'MuseoSans-500';
//   font-size: 17px;
//   padding: 10px 10px 25px 10px;
// `;

const StyledSpinner = styled.img`
  height: 34px;
  margin-top: 40px;
`;

StandingsTable.propTypes = {
  style: PropTypes.object,
  standings: PropTypes.array,
  referentialScore: PropTypes.number,
  isLA: PropTypes.bool,
  loading: PropTypes.bool,
};

StandingsTable.defaultProps = {
  style: {},
  standings: [],
  referentialScore: 0,
  isLA: false,
  loading: false,
};

export default StandingsTable;
