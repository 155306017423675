import React from 'react';
import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';
import winnerPic from '../assets/images/winner2020@1.5x.png';
import runnerUpPic from '../assets/images/runnerup.png';
import cornerRainbow from '../assets/images/corner-rainbow.png';
import colors from '../utils/colors';

const WinnerSection = () => (
  <Wrapper>
    <Winner>
      <WinnerImg src={winnerPic} />
      <WinnerDesc>
        <WinnerYear>2019</WinnerYear>
        <WinnerTitle>
          Stoli Key West Cocktail Classic North American Champion
        </WinnerTitle>
        <WinnerName>First Name, Last Name</WinnerName>
        <WinnerInfo>City, State</WinnerInfo>
        <WinnerInfo>“Cocktail Name”</WinnerInfo>
        <WinnerInfo>$10k for hometown charity ____</WinnerInfo>
        <WinnerInfo>$5k for Key West paired charity ___</WinnerInfo>
      </WinnerDesc>
    </Winner>
    <Runners>
      <RunnerUpWrapper>
        <RunnerUpTitle>
          1
          <RunnerUpAbr>st</RunnerUpAbr>
          {' '}
          Runner up
        </RunnerUpTitle>
        <RunnerUp>
          <RunnerUpImg src={runnerUpPic}>
            <img src={runnerUpPic} alt="runner up name" />
          </RunnerUpImg>
          <RunnerUpDetails>
            <RunnerUpName>Josh Cole Simmons</RunnerUpName>
            <RunnerUpCity>Dallas, Texas</RunnerUpCity>
          </RunnerUpDetails>
        </RunnerUp>
      </RunnerUpWrapper>
      <RunnerUpWrapper>
        <RunnerUpTitle>
1
          <RunnerUpAbr>st</RunnerUpAbr>
          {' '}
          Runner up
        </RunnerUpTitle>
        <RunnerUp>
          <RunnerUpImg src={runnerUpPic}>
            <img src={runnerUpPic} alt="runner up name" />
          </RunnerUpImg>
          <RunnerUpDetails>
            <RunnerUpName>Josh Cole Simmons</RunnerUpName>
            <RunnerUpCity>Dallas, Texas</RunnerUpCity>
          </RunnerUpDetails>
        </RunnerUp>
      </RunnerUpWrapper>
      <RunnerUpWrapper>
        <RunnerUpTitle>
        1
          <RunnerUpAbr>st</RunnerUpAbr>
          {' '}
          Runner up
        </RunnerUpTitle>
        <RunnerUp>
          <RunnerUpImg src={runnerUpPic}>
            <img src={runnerUpPic} alt="runner up name" />
          </RunnerUpImg>
          <RunnerUpDetails>
            <RunnerUpName>Josh Cole Simmons</RunnerUpName>
            <RunnerUpCity>Dallas, Texas</RunnerUpCity>
          </RunnerUpDetails>
        </RunnerUp>
      </RunnerUpWrapper>
      <RunnerUpWrapper>
        <RunnerUpTitle>
1
          <RunnerUpAbr>st</RunnerUpAbr>
          {' '}
          Runner up
        </RunnerUpTitle>
        <RunnerUp>
          <RunnerUpImg src={runnerUpPic}>
            <img src={runnerUpPic} alt="runner up name" />
          </RunnerUpImg>
          <RunnerUpDetails>
            <RunnerUpName>Josh Cole Simmons</RunnerUpName>
            <RunnerUpCity>Dallas, Texas</RunnerUpCity>
          </RunnerUpDetails>
        </RunnerUp>
      </RunnerUpWrapper>
    </Runners>
    <WinnersFootnote>
      <InnerWrapper>
        <Description>
          For photos from this competition as well as other exciting updates,
           stay in touch at:&nbsp;
          <Link href="https://www.out.com/keywestcocktailclassic" target="_blank">
            <strong>https://www.out.com/keywestcocktailclassic</strong>
          </Link>
        </Description>
        <RainbowDetail />
      </InnerWrapper>
    </WinnersFootnote>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
`;

const Winner = styled.div`
  width: 56.71875vw;
  margin: 6.796875vw auto 0;
  min-height: 19.84375vw;
  display: flex;
  @media (max-width: ${breakpoints.md.max}px) {
    width: 84.6354166667vw;
    min-height: 31.9010416667vw;
    margin: 14.0625vw auto 0;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    flex-direction: column;    
    width: 81.8666666667vw;
    min-height: auto;
    margin-top: 8.8vw;
  }
`;

const WinnerImg = styled.div`
  background-image: url(${props => props.src});
  background-repeat:no-repeat;
  background-position: center;
  background-size: cover;
  width: 50%;
  flex: 0 0 auto;
  @media (max-width: ${breakpoints.sm.max}px) {
    width: 100%;
    height: 61.6vw;
  }
`;

const WinnerDesc = styled.div`
  width: 50%;
  padding: 4.375vw 1.40625vw 1.875vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  background-color: ${colors.white};
  background-size: 10.390625vw;
  background: url(${cornerRainbow}) no-repeat top left;
  text-align: center;
  @media (max-width: ${breakpoints.md.max}px) {
    background-size: 15.625vw;
    padding: 4.296875vw 2.6041666667vw 2.6041666667vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    background-size: 30.1333333333vw;
    width: 100%;
    min-height: 61.6vw;
    padding: 12.8vw 4.8vw 4.8vw;
  }
`;

const WinnerTitle = styled.h2`
  color: ${colors.winnerRed};
  font-family: Verdana;
  font-size: 1.25vw;
  font-weight: 700;
  letter-spacing: 0.0625vw;
  line-height: 1.25;
  margin: 0 0 1.875vw;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.34375vw;
    line-height: 1.39;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 4.8vw;
    line-height: 1.39;
  }
`;

const WinnerYear = styled(WinnerTitle)`
  font-size: 1.328125vw;
  margin: 0;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.34375vw;
  }
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 4.8vw;
  }
`;

const WinnerName = styled.h3`
  font-family: Verdana;
  font-size: 1.015625vw;
  font-weight: 700;
  margin: 0;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.953125vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 4vw;
  }
`;

const WinnerInfo = styled.div`
  color: ${colors.black};
  font-family: Verdana;
  font-size: 0.859375vw;
  font-weight: 400;
  letter-spacing: 0.04453125vw;
  line-height: 1.52;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.8229166667vw;
    line-height: 1.43;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 3.2vw;
    line-height: 1.67;
  }
`;

const Runners = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5.46875vw; 
  @media (max-width: ${breakpoints.md.max}px) {
    margin-top: 11.9791666667vw;
    flex-wrap: wrap;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    margin-top: 26.6666666667vw;
    flex-wrap: wrap;
  }
`;

const RunnerUpWrapper = styled.div`
  width: 19.53125vw;
  flex: 0 0 auto;
  @media (max-width: ${breakpoints.md.max}px) {
    width: 33.8541666667vw;
    margin-bottom: 6.25vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    width: 69.3333333333vw;
    margin-bottom: 17.0666666667vw;
  }
  
  & + & {
    margin-left: 1.328125vw;
    @media (max-width: ${breakpoints.md.max}px) {
      margin-left: 2.34375vw;
    }
    @media (max-width: ${breakpoints.sm.max}px) {
      margin-left: 0;
    }
  }
`;

const RunnerUp = styled.div`
`;

const RunnerUpImg = styled.div`
  background-image: url(${props => props.src});
  background-repeat:no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 14.84375vw;
  @media (max-width: ${breakpoints.md.max}px) {
      height: 25.78125vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
      height: 53.3333333333vw;
  }
  img {
    padding-left: 100%;
  }
`;

const RunnerUpTitle = styled.h2`
  color: ${colors.runnerUpTitle};
  font-family: Verdana;
  font-size: 1.09375vw;
  font-weight: 700;
  letter-spacing: 0.0546875vw;
  line-height: 2.86;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.734375vw;
    line-height: 2.86;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 5.6vw;
  }
`;

const RunnerUpAbr = styled.span`
  font-size: 0.625vw;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.5625vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 3.2vw;
  }
`;

const RunnerUpCity = styled.p`
  color: ${colors.black};
  font-family: Tahoma;
  font-size: 0.859375vw;
  font-weight: 400;
  letter-spacing: 0.04453125vw;
  line-height: 1.52;
  margin: 0;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.953125vw;
    line-height: 1.33;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 3.7333333333vw;
    line-height: 1.43;
  }
`;

const RunnerUpName = styled.h3`
  color: ${colors.black};
  font-family: Tahoma;
  font-size: 0.859375vw;
  font-weight: 400;
  letter-spacing: 0.04453125vw;
  line-height: 1.52;
  font-weight: 700;
  margin: 0;
  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.953125vw;
    line-height: 1.33;
    font-weight: 700;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 3.7333333333vw;
    line-height: 1.43;
    font-weight: 700;
  }

`;

const RunnerUpDetails = styled.div` 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  background-color: ${colors.white};
  text-align: center;
  padding: 1.015625vw;
  @media (max-width: ${breakpoints.md.max}px) {
    padding: 1.6927083333vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    padding: 3.4666666667vw;
  }
`;

const WinnersFootnote = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 4.8828125vw 0;
  background-color: rgba(230, 230, 230, .45);
  margin-top: 6.25vw;
  @media (max-width: ${breakpoints.md.max}px) {
    padding: 6.9010416667vw 0;
    margin-top: 10.15625vw;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    padding: 10.1333333333vw 0;
    margin-top: 10.1333333333vw;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.div`
  max-height: 3.3333333333vw;
  min-width: 53.229167vw;
  color: ${colors.black};
  font-family: Verdana;
  font-size: 1.171875vw;
  font-weight: 400;
  letter-spacing: 0.05703125vw;
  line-height: 1.56;
  width: 43.203125vw;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${breakpoints.md.max}px) {
    max-height: 12.5vw;
    min-width: 78.125vw;
    margin-bottom: 6.9010416667vw;
    font-size: 2.6041666667vw;
    font-weight: 400;
    letter-spacing: 0.1302083333vw;
    line-height: 3.90625vw;
  }

  @media (max-width: ${breakpoints.md.max}px) {
    max-height: 21.3333333333vw;
    min-width: 77.8666666667vw;
    margin-bottom: 8.5333333333vw;
    font-size: 3.7333333333vw;
    font-weight: 400;
    line-height: 4.8vw;
    letter-spacing: 0.1866666667vw;
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  color: ${colors.black};
  @media (max-width: ${breakpoints.sm.max}px) {
    display: block;
    text-align: center;
  }
`;

const RainbowDetail = styled.div`
  width: 4.84375vw;
  height: 0.390625vw;
  background-image: linear-gradient(270deg, #ff0000 0%, #ff8900 21%, #fcf200 41%, #26a200 59%, #1e2bc9 77%, #6e009f 100%, rgba(128, 128, 128, 0) 100%);
  margin: 2.34375vw auto 0;

  @media (max-width: ${breakpoints.md.max}px) {
    width: 12.109375vw;
    height: 1.0416666667vw;
    margin-top: 5.2083333333vw;
  }

  @media (max-width: ${breakpoints.sm.max}px) {
    width: 24.8vw;
    height: 2.1333333333vw;
    margin-top: 5.8666666667vw;
  }
`;

export default WinnerSection;
