import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Collapse from 'react-collapse';
import One from '../assets/svg/one.svg';
import ArrowDown from '../assets/svg/arrow-down-accordion.svg';
import ArrowRight from '../assets/svg/arrow-right-accordion.svg';

const labels = ['Overall', 'Blind Sampling', 'Text Votes', 'Cocktail', 'Presentation'];

class CollapsedRow extends Component {
  state = { isOpen: false }

  toggleCollapse = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  renderPlace = (index) => {
    const endings = ['st', 'nd', 'rd', 'th'];

    return index === 0 ? (
      <Place>
        <StyledOne />
        <Ending first>{endings[index]}</Ending>
      </Place>
    )
      : (
        <Place>
          <Number>{index + 1}</Number>
          <Ending>{index > 3 ? endings[3] : endings[index]}</Ending>
        </Place>
      );
  }

  renderFraction = (numerator = 0) => {
    const { referentialScore } = this.props;
    return (
      <Fraction>
        {numerator}
        <Denominator>{`/${referentialScore}`}</Denominator>
      </Fraction>
    );
  }

  render = () => {
    const { standing, isTie, index, last, columnWidth, isLA } = this.props;
    const { isOpen } = this.state;
    return (
      <Row last={last}>
        <StandingRow>
          <StandingData width={columnWidth[0]}>{this.renderPlace(index)}</StandingData>
          <StandingData width={columnWidth[1]}>
            {standing.name}
            {isLA && '*'}
          </StandingData>
          <ArrowContainer onClick={this.toggleCollapse}>
            {isOpen ? <ArrowDown /> : <ArrowRight />}
          </ArrowContainer>
        </StandingRow>
        <Collapse isOpened={isOpen}>
          <CollapseWrapper>
            <DataRow>
              <Label>{labels[0]}</Label>
              <Data>
                {`${standing.overall || 0}%`}
                {isTie && (
                  <TieIndicator>*TIE</TieIndicator>
                )}
              </Data>
            </DataRow>
            <DataRow>
              <Label>{labels[1]}</Label>
              <Data>{standing.blindSampling || 0}</Data>
            </DataRow>
            <DataRow>
              <Label>{labels[2]}</Label>
              <Data>{standing.textVotes || 0}</Data>
            </DataRow>
            <DataRow>
              <Label>{labels[3]}</Label>
              <Data>{this.renderFraction(standing.cocktail)}</Data>
            </DataRow>
            <DataRow>
              <Label>{labels[4]}</Label>
              <Data>{this.renderFraction(standing.presentation)}</Data>
            </DataRow>
            { isLA && index === 0 && (
              <Succession>
                2019 Champion for Los Angeles by succession is Tamara Malais.
                Tamara tied for first place in 2018 and ultimately placed second
                only by sudden death shot round tie-breaker.
              </Succession>
            )}
          </CollapseWrapper>
        </Collapse>
      </Row>
    );
  }
}

const TableRow = styled.div`
  display: flex;
  position: relative;
`;

const Row = styled.div`
  border-bottom-width: ${props => props.last ? 0 : 1}px;
  border-bottom-style: solid;
  border-bottom-color: #D9B86E;
  position: relative;
`;

const StandingRow = styled(TableRow)`
  align-items: center;
  min-height: 60px;
  font-family: 'MuseoSans-500';
`;

const StandingData = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.width};
  text-align: center;
`;

const Place = styled.div`
  display: flex;
`;

const StyledOne = styled(One)`
  max-width: 13px;
`;

const Ending = styled.div`
  font-size: 14px;
  font-family: 'SignPainter';
  line-height: ${props => props.first ? '24' : '10'}px;
`;

const Number = styled.div`
  font-size: 27px;
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 5px;
  right: 6px;
`;

const CollapseWrapper = styled.div`
  padding-bottom: 15px;
`;

const DataRow = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  margin-left: 34px;
  position: relative;
`;

const Label = styled.div`
  width: 100px;
  font-family: 'Tahoma';
  font-size: 11px;
`;

const Data = styled.div`
  font-family: 'MuseoSans-500';
  position: relative;
`;

const Fraction = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Denominator = styled.div`
  font-size: 12px;
  line-height: 22px;
`;

const TieIndicator = styled.div`
  position: absolute;
  top: 0.5em;
  right: -2.5em;
  font-family: 'MuseoSans-500';
  font-size: 0.8em;
  letter-spacing: 0.05em;
  font-weight: bolder;
  line-height: 0.8em;
  color: #d6af53;
`;

const Succession = styled.div`
  font-family: 'MuseoSans-500';
  font-size: 13px;
  line-height: 20px;
  margin: 5px 20px 10px 34px;
`;

CollapsedRow.propTypes = {
  standing: PropTypes.object,
  isTie: PropTypes.bool,
  index: PropTypes.number.isRequired,
  last: PropTypes.bool,
  columnWidth: PropTypes.array,
  referentialScore: PropTypes.number,
  isLA: PropTypes.bool,
};

CollapsedRow.defaultProps = {
  standing: {},
  isTie: false,
  last: false,
  columnWidth: [],
  referentialScore: 0,
  isLA: false,
};

export default CollapsedRow;
