import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import keyWest from '../assets/images/key-west-logo.png';
import stolimpics from '../assets/images/Stoli_Stolimpics_logo.png';
import breakpoints from '../utils/breakpoints';

const EventLogos = ({ style, blur, showInitialLanding }) => {
  return (
    <LogosWrapper style={style} showInitialLanding={showInitialLanding} className={blur && 'blur'}>
      <StyledStolimpics src={stolimpics} showInitialLanding={showInitialLanding} />
      <StyledKeyWest src={keyWest} showInitialLanding={showInitialLanding} />
    </LogosWrapper>
  );
};

const LogosWrapper = styled.div`
  margin-top: ${props => props.showInitialLanding ? '0' : '5.125vw'};
  margin-bottom: ${props => props.showInitialLanding ? '-1.0416666667vw' : '0'};
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${props => props.showInitialLanding ? 'static' : 'relative'};
  top: 0;
  z-index: 1;

  @media (max-width: ${breakpoints.md.max}px) {
    display: ${props => props.showInitialLanding ? 'flex' : 'none'};
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    display: flex;
    max-width: 70.1333333333vw;
    margin-top: 0;
    top: 18.4vw;
    z-index: 0;
    margin-bottom: 4.5333333333vw;
    margin-top: 3.4666666667vw;
  }
`;

const StyledStolimpics = styled.img`
  height: 6.6145833333vw;
  background-color: white;
  margin: 2.0833333333vw 1.6666666667vw;
  
  @media (max-width: ${breakpoints.sm.max}px) {
    height: ${props => props.showInitialLanding ? '6.6145833333vw' : '17.0666666667vw'};
    margin: ${props => props.showInitialLanding ? '2.0833333333vw 1.6666666667vw' : '3.7333333333vw 9.3333333333vw'};
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    height: 17.0666666667vw;
  }
`;

const StyledKeyWest = styled.img`
  height: 8.9583333333vw;
  margin: 2.0833333333vw 1.6666666667vw;
  background-color: white;

  @media (max-width: ${breakpoints.sm.max}px) {
    height: ${props => props.showInitialLanding ? '8.9583333333vw' : '23.467vw'};
    margin: ${props => props.showInitialLanding ? '2.0833333333vw 1.6666666667vw' : '3.7333333333vw 9.3333333333vw 3.7333333333vw 0'};
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    height: 23.467vw;
  }
`;

EventLogos.propTypes = {
  style: PropTypes.object,
  blur: PropTypes.bool,
  showInitialLanding: PropTypes.bool,
};

EventLogos.defaultProps = {
  style: {},
  blur: false,
  showInitialLanding: false,
};

export default EventLogos;
