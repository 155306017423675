import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Logo from '../assets/svg/logo-stoli-white.svg';
import rainbow from '../assets/images/background-lines.svg';
import stolimpics from '../assets/images/Stoli_Stolimpics_logo.png';
import keyWest from '../assets/images/key-west-logo.png';
import colors from '../utils/colors';
import breakpoints from '../utils/breakpoints';

const Header = ({ blur, showHeader }) => (
  <Background className={blur && 'blur'} showHeader={showHeader}>
    <StyledLogo />
    <ContentWrapper>
      <StyledRainbow />
      <LogosContainer>
        <StyledStolimpics src={stolimpics} />
        <StyledKeyWest src={keyWest} />
      </LogosContainer>
    </ContentWrapper>
  </Background>
);

const Background = styled.div`
  background-color: ${colors.red};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  @media (max-width: ${breakpoints.xs.max}px) {
    display: ${props => props.showHeader && 'none'}; 
  }
`;

const StyledLogo = styled(Logo)`
  max-height: 8.1770833333vw;
  position: absolute;

  @media (max-width: ${breakpoints.md.max}px) {
    max-height: 8vw;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 0;
  justify-content: flex-end;
`;

const StyledRainbow = styled.div`
  width: 58.95vw;
  max-width: 58.95vw;
  max-height: 9vw;
  z-index: 1;
  right: -18.198vw;
  position: relative;
  background-image: url(${rainbow});
  background-position: right top -10px;
  background-repeat: no-repeat;
  background-size: cover;
`;

const LogosContainer = styled.div`
  min-width: 35.21vw;
  min-height: 9vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: white;
`;

const StyledStolimpics = styled.img`
  height: 5.78125vw;
  margin: 0.46875vw 2.1354166667vw;
  background-color: white;

  @media (max-width: ${breakpoints.lg.max}px) {
    height: 5.7291666667vw;
  }
`;

const StyledKeyWest = styled.img`
  height: 7.2395833333vw;
  margin: 0.46875vw 3.6458333333vw 0.46875vw 0;
  background-color: white;

  @media (max-width: ${breakpoints.lg.max}px) {
    height: 5.7291666667vw;
  }
`;


Header.propTypes = {
  blur: PropTypes.bool,
  showHeader: PropTypes.bool,
};

Background.defaultProps = {
  blur: true,
  showHeader: true,
};

export default Header;
